import { Loader } from 'google-maps';

function mapBlockB() {
    const mapBlock = document.querySelector('[data-role="map-block"]');
    if (!mapBlock) {
        return;
    }
    const loader = new Loader(mapBlock.dataset.key, {});
    const markerLocation = {
        lat: parseFloat(mapBlock.dataset.lat),
        lng: parseFloat(mapBlock.dataset.lng),
    };
    let marker = null;
    const markerIcon = mapBlock.dataset.markerIcon ?? '';

    loader.load().then((google) => {
        const mapZoom = 15;

        const mapArgs = {
            zoom: mapZoom,
            center: markerLocation,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            streetViewControl: false,
        };

        // Init map
        const map = new window.google.maps.Map(mapBlock, mapArgs);
        marker = addMarker(map);
    });

    function addMarker(map) {
        return new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
                markerLocation.lat,
                markerLocation.lng,
            ),
            map,
            icon: getMarkerIcon(),
            infoWindow: new window.google.maps.InfoWindow({
                maxWidth: 300,
            }),
        });
    }

    function getMarkerIcon() {
        if (markerIcon) {
            return {
                url: markerIcon,
                size: new window.google.maps.Size(46, 46),
                scaledSize: new window.google.maps.Size(50, 50),
            };
        }

        // fallback custom svg icon for the Marker
        return {
            path: 'M9.856 0c-1.52 0-3.04.316-4.327.948-2.066 1.015-3.845 2.84-4.814 4.94-.984 2.134-.948 6.367.08 9.383 1.383 4.056 3.553 7.958 6.916 12.433C8.661 28.967 9.63 30 9.865 30c.936 0 5.477-6.686 7.602-11.193 1.857-3.937 2.466-6.434 2.293-9.395-.223-3.824-2.182-6.797-5.577-8.464C12.896.316 11.376 0 9.856 0Zm.043 6.942c1.839-.013 3.78 1.562 3.622 4.212-.074.947-.678 2-1.426 2.49-2.583 1.692-5.882-.08-5.882-3.162 0-2.044 1.233-3.316 3.416-3.526a3.141 3.141 0 0 1 .27-.014z',
            fillColor: '#000',
            fillOpacity: 1,
            anchor: new window.google.maps.Point(9.894, 30),
            strokeWeight: 0,
            scale: 1,
        };
    }
}

export default mapBlockB;
