import MicroModal from 'micromodal';

export default class ValueYourTradeWidget {
    /**
     * The single instance of this class following the Singleton pattern.
     * @type {null|ValueYourTradeWidget}
     */
    static instance = null;

    /**
     * ID of the modal window
     * @type {string}
     */
    static MODAL_ID = 'value-your-trade-widget-modal';

    /**
     * Selector string for buttons that, when clicked, open the modal window.
     * @type {string}
     */
    static BUTTON_SELECTOR = "[data-role='value-your-trade-widget-btn']";

    /**
     * Flag indicating whether thw widget is already loaded from the backend side
     * @type {boolean}
     */
    isLoaded = false;

    /**
     * Flag indicating whether an AJAX call is currently in progress.
     * @type {boolean}
     */
    isProcessingAjax = false;

    /**
     *
     * @type {HTMLElement|null}
     */
    modalElement = null;

    constructor() {
        this.modalElement = document.getElementById(
            ValueYourTradeWidget.MODAL_ID,
        );
        if (!this.modalElement || window.valueYourTradeWidget === undefined) {
            return;
        }
        // Data from backend
        // URL for ajax requests
        // eslint-disable-next-line no-undef
        this.ajaxUrl = window.valueYourTradeWidget.url;
        // Name of the action
        // eslint-disable-next-line no-undef
        this.ajaxAction = window.valueYourTradeWidget.action;
        // Nonce code for ajax requests
        // eslint-disable-next-line no-undef
        this.ajaxNonce = window.valueYourTradeWidget.nonce;

        this.addEventListeners();
    }

    /**
     * Attach Event Listeners
     */
    addEventListeners() {
        window.addEventListener('click', this.buttonClickHandler.bind(this));
    }

    /**
     * Fires when a button is clicked
     * @param {Event} event
     */
    buttonClickHandler(event) {
        const target = event.target;

        if (!target.closest(ValueYourTradeWidget.BUTTON_SELECTOR)) {
            return;
        }

        event.preventDefault();
        MicroModal.show(ValueYourTradeWidget.MODAL_ID, {
            disableScroll: true,
        });
        if (!this.isLoaded) {
            this.loadContent();
        }
    }

    loadContent() {
        $.ajax({
            type: 'POST',
            url: this.ajaxUrl,
            data: this.getRequestData(),
            beforeSend: () => {
                this.isProcessingAjax = true;
            },
            success: (response) => {
                const contentArea = this.modalElement.querySelector(
                    "[data-role='content-area']",
                );
                if (!contentArea) {
                    return;
                }
                if (response.success) {
                    contentArea.innerHTML = response?.data?.output ?? '';
                    this.processScriptTags(contentArea);
                } else {
                    const errorMessage = response?.data?.message ?? '';
                    if (errorMessage) {
                        contentArea.innerHTML = errorMessage;
                    }
                }
            },
            // eslint-disable-next-line no-unused-vars
            complete: (xhr) => {
                this.isLoaded = true;
                this.isProcessingAjax = false;
            },
            error: (xhr) => {
                console.log('error:', xhr);
            },
        });
    }

    /**
     * Process inner HTML of the passed container and replace <script> tags to make them work
     * @param {HTMLElement} container
     */
    processScriptTags(container) {
        // Get all script elements within the passed container
        let scriptTags = container.querySelectorAll('script');
        // Looping over all of them and replace with proper script tags
        scriptTags.forEach((script) => {
            let scriptInnerHTML = script.innerHTML;
            let scriptSrc = script.getAttribute('src');
            if (scriptInnerHTML || scriptSrc) {
                // Adding proper script tags to the container element
                const scriptTag = document.createElement('script');
                if (scriptSrc) {
                    scriptTag.setAttribute('src', scriptSrc ?? '');
                }
                if (scriptInnerHTML) {
                    scriptTag.innerHTML = scriptInnerHTML ?? '';
                }

                container.appendChild(scriptTag);
                script.remove();
            }
        });
    }

    /**
     * Returns an object that will be sent to backend side
     * @return {{action, _nonce}}
     */
    getRequestData() {
        return {
            action: this.ajaxAction,
            _nonce: this.ajaxNonce,
        };
    }

    /**
     * Returns a single instance of this class (following the Singleton pattern)
     * @return {ValueYourTradeWidget}
     */
    static getInstance() {
        if (ValueYourTradeWidget.instance === null) {
            ValueYourTradeWidget.instance = new ValueYourTradeWidget();
        }

        return ValueYourTradeWidget.instance;
    }
}
