class PaymentsCalculator {
    /**
     *
     * @type {HTMLElement|null}
     */
    container = null;

    /**
     *
     * @type {null|number}
     */
    networkPrice = null;

    /**
     *
     * @type {HTMLElement|null}
     */
    calculationResultsElement = null;

    /**
     *
     * @type {HTMLInputElement | null}
     */
    priceField = null;
    /**
     *
     * @type {HTMLInputElement|null}
     */
    downPaymentField = null;
    /**
     *
     * @type {HTMLInputElement|null}
     */
    paymentWrapper = null;
    /**
     *
     * @type {HTMLInputElement|null}
     */
    tradeInField = null;
    /**
     *
     * @type {HTMLInputElement|null}
     */
    payoffField = null;
    /**
     *
     * @type {HTMLInputElement|null}
     */
    additionalDownPaymentField = null;
    /**
     *
     * @type {HTMLSelectElement|null}
     */
    termField = null;
    /**
     *
     * @type {HTMLCollection|null}
     */
    financeRateFields = null;
    /**
     *
     * @type {HTMLSelectElement|null}
     */
    creditScoreRateField = null;
    /**
     *
     * @type {HTMLInputElement|null}
     */
    customRateField = null;

    /**
     *
     * @type {HTMLButtonElement | null}
     */
    downPaymentExpandButton = null;

    /**
     *
     * @type {HTMLElement|null}
     */
    tooltipsContainer = null;

    /**
     *
     * @type {HTMLElement|null}
     */
    downPaymentsFields = null;

    /**
     *
     * @type {boolean}
     */
    tooltipOpened = false;

    /**
     *
     * @type {boolean}
     */
    downPaymentsFieldsOpened = false;

    /**
     *
     * @type {HTMLLinkElement|null}
     */
    calculatorSubmitButton = null;
    /**
     *
     * @type {HTMLDivElement|null}
     */
    personalInfoFormContainer = null;

    calculationsData = {
        networkPrice: null,
        downPayment: null,
        tradeIn: null,
        payoff: null,
        additionalDownPayment: null,
        term: null,
        financeRate: null,
        creditScoreRate: null,
        customRate: null,
        estimatedMonthlyPayments: null,
    };

    constructor(container) {
        this.container = container;
        this.networkPrice = parseFloat(container.dataset?.networkPrice);
        this.calculationResultsElement = container.querySelector(
            `[data-role='results']`,
        );

        this.priceField = container.querySelector(
            `input[name='network-price']`,
        );
        this.downPaymentField = container.querySelector(
            `input[name='down-payment']`,
        );
        this.paymentWrapper = container.querySelector(
            `div[data-role='payment-wrapper']`,
        );
        this.tradeInField = container.querySelector(`input[name='trade-in']`);
        this.payoffField = container.querySelector(`input[name='payoff']`);
        this.additionalDownPaymentField = container.querySelector(
            `input[name='additional-down-payment']`,
        );
        this.termField = container.querySelector(`select[name='term']`);
        this.financeRateFields = container.querySelectorAll(
            `input[name='finance-rate']`,
        );
        this.creditScoreRateField = container.querySelector(
            `select[name='credit-score-rate']`,
        );
        this.customRateField = container.querySelector(
            `input[name='custom-rate']`,
        );

        this.downPaymentExpandButton = container.querySelector(
            `button[data-role='down-payment-expand']`,
        );
        this.downPaymentsFields = container.querySelector(
            `[data-role='down-payments-fields']`,
        );

        this.tooltipsContainer = container.querySelector(
            `[data-role='tooltip-area']`,
        );

        this.calculatorSubmitButton = container.querySelector(
            `[data-role='calculator-submit-button']`,
        );
        this.personalInfoFormContainer = container.querySelector(
            `[data-role='personal-info-form']`,
        );

        // Run
        this.init();
        // Attach listeners
        this.initListeners();
    }

    /**
     * Returns initial calculations data
     * @return {*&{financeRate: string, creditScoreRate: number, downPayment: null, tradeIn: null, networkPrice: (number|null), payoff: null, customRate: number, term: number, additionalDownPayment: null}}
     */
    getInitialCalculationsData() {
        return {
            ...this.calculationsData,
            networkPrice: this.networkPrice,
            downPayment: null,
            tradeIn: null,
            payoff: null,
            additionalDownPayment: null,
            term: 48,
            financeRate: 'custom-rate',
            creditScoreRate: 12.61,
            customRate: 12.61,
            estimatedMonthlyPayments: null,
        };
    }

    /**
     * Initializes the calculator functionality
     */
    init() {
        // Set default values
        this.calculationsData = this.getInitialCalculationsData();
        // Set fields values with default ones
        this.updateFieldsByCalculationsData();
        // Set initial state for the down payments area
        this.updateDownPaymentsArea();

        // Re-calculate the price
        this.refreshPrice();
    }

    /**
     * Attaches event listeners
     */
    initListeners() {
        // Click events
        this.container.addEventListener('click', this.clicksHandler.bind(this));
        // Change events
        this.container.addEventListener(
            'change',
            this.changeHandler.bind(this),
        );
        // Down Payments fields area clicks
        this.downPaymentExpandButton.addEventListener(
            'click',
            this.downPaymentFieldsToggle.bind(this),
        );
        if (this.calculatorSubmitButton) {
            this.calculatorSubmitButton.addEventListener(
                'click',
                this.calculatorSubmitButtonHandler.bind(this),
            );
        }
    }

    /**
     * Fires when there is a click on the calculator container element
     * @param {Event} event
     */
    clicksHandler(event) {
        const target = event.target;

        if (
            target.closest(`button[data-role='tooltip-button'][data-tooltip]`)
        ) {
            this.tooltipButtonHandler(event);
        } else if (
            this.tooltipOpened &&
            target.closest(`[data-role='tooltip-area']`)
        ) {
            this.tooltipAreaClickHandler(event);
        }
    }

    /**
     * Fires when there is click on the open-tooltip button
     * @param {Event} event
     */
    tooltipButtonHandler(event) {
        event.preventDefault();

        // Button itself
        const button = event.target.closest(
            `button[data-role='tooltip-button'][data-tooltip]`,
        );
        // Corresponding tooltip slug
        const tooltip = button.dataset.tooltip;
        // Corresponding tooltip element
        const tooltipElement = this.tooltipsContainer.querySelector(
            `[data-tooltip='${tooltip}']`,
        );
        // If such found
        if (tooltipElement) {
            // Close all the rest tooltip elements and show the clicked one
            const tooltips =
                this.tooltipsContainer.querySelectorAll(`[data-tooltip]`);
            tooltips.forEach((currentTooltip) => {
                if (currentTooltip !== tooltipElement) {
                    currentTooltip.classList.add('hidden');
                } else {
                    currentTooltip.classList.remove('hidden');
                }
            });
            // Smoothly opens the tooltip window
            jQuery(this.tooltipsContainer).fadeIn(200, () => {
                this.tooltipOpened = true;
            });
        }
    }

    /**
     * Fires when there is a click on the tooltip-area window
     * @param {Event} event
     */
    tooltipAreaClickHandler(event) {
        event.preventDefault();

        // Clicked element
        const target = event.target;
        // If target is inside of tooltip itself but outside of the close button do nothing
        if (
            target.closest(`[data-tooltip]`) &&
            !target.closest(`[data-role='tooltip-close']`)
        ) {
            return;
        }
        // Otherwise, close the tooltip window
        jQuery(this.tooltipsContainer).fadeOut(200, () => {
            this.tooltipOpened = false;
        });
    }

    /**
     * Fires when there is a change event on the calculator container element
     * @param {Event} event
     */
    changeHandler(event) {
        // Target element
        const target = event.target;
        // Modified value of the current element
        const currentValue = target.value;

        /**
         * @typedef {Object} Update
         * @property {HTMLElement|EventTarget} field - The field being updated
         * @property {null|string|number} fieldValue - The value of the field being updated
         * @property {null|string|number} dataValue - Additional data value associated with the update
         * @property {null|string} dataKey - Key associated with the additional data
         * @property {boolean} updateFieldValue - Whether to update the field value
         */

        // Update item sample
        /** @type {Update} */
        let update = {
            field: target,
            fieldValue: null,
            dataValue: null,
            dataKey: null,
            updateFieldValue: true,
        };

        /** @type {Update[]} */
        let updates = [];

        if (target === this.priceField) {
            // Network Price field
            updates.push({
                field: target,
                fieldValue: this.formatPriceValue(currentValue),
                dataValue: this.getNumber(currentValue),
                dataKey: 'networkPrice',
                updateFieldValue: true,
            });
        } else if (target === this.tradeInField) {
            // Trade In field
            this.tradeInFieldChangeHandler(target);
        } else if (target === this.payoffField) {
            // Payoff field
            this.payoffFieldChangeHandler(target);
        } else if (target === this.additionalDownPaymentField) {
            // Additional down payment field
            this.additionalDownPaymentChangeHandler(target);
        } else if (target === this.downPaymentField) {
            // Down Payment field
            updates.push({
                field: target,
                fieldValue: this.formatPriceValue(currentValue),
                dataValue: this.getNumber(currentValue),
                dataKey: 'downPayment',
                updateFieldValue: true,
            });
        } else if (target === this.termField) {
            // Term field
            updates.push({
                field: target,
                fieldValue: null,
                dataValue: this.getNumber(currentValue),
                dataKey: 'term',
                updateFieldValue: false,
            });
        } else if ([...this.financeRateFields].includes(target)) {
            // Finance Rate field
            updates.push({
                field: target,
                fieldValue: null,
                dataValue: currentValue,
                dataKey: 'financeRate',
                updateFieldValue: false,
            });
        } else if (target === this.creditScoreRateField) {
            // Credit score rate field
            updates.push({
                field: target,
                fieldValue: null,
                dataValue: this.getFloat(currentValue),
                dataKey: 'creditScoreRate',
                updateFieldValue: false,
            });
            updates.push({
                field: this.customRateField,
                fieldValue: this.formatPercentValue(currentValue),
                dataValue: this.getFloat(currentValue),
                dataKey: 'customRate',
                updateFieldValue: true,
            });
        } else if (target === this.customRateField) {
            // Custom rate field
            updates.push({
                field: target,
                fieldValue: this.formatPercentValue(currentValue),
                dataValue: this.getFloat(currentValue),
                dataKey: 'customRate',
                updateFieldValue: true,
            });
        }

        // Update field value and calculations data if needed
        updates.forEach((update) => {
            if (update.dataKey) {
                if (update.updateFieldValue && update.field) {
                    update.field.value = update.fieldValue;
                }
                this.calculationsData[update.dataKey] = update.dataValue;
            }
        });
        this.refreshPrice();
    }

    /**
     * Fires when the tradein field is being changed
     * @param {HTMLInputElement} field
     */
    tradeInFieldChangeHandler(field) {
        // Raw value of the field
        const rawValue = field.value;
        // Extracted numbers from a raw value
        const numericValue = this.getNumber(rawValue);
        // Building a price string
        const formattedValue = this.formatPriceValue(rawValue);

        // Update calculations data
        this.calculationsData.tradeIn = numericValue;
        // Update field value with a price string
        field.value = formattedValue;

        // Validation: trade in field value cannot be less than the payoff field value.
        // If so, update the payoff field value
        let payoff = this.calculationsData.payoff ?? 0;
        if (numericValue < payoff) {
            this.calculationsData.payoff = numericValue;
            this.payoffField.value = formattedValue;
        }

        // Updating the down payment field
        const downPayment = this.getDownPaymentFromAdditionalFields();
        this.calculationsData.downPayment = downPayment;
        this.downPaymentField.value = this.formatPriceValue(downPayment);

        // Refresh the price
        this.refreshPrice();
    }

    /**
     * Fires when the payoff field is being changed
     * @param {HTMLElement} field
     */
    payoffFieldChangeHandler(field) {
        // Raw value of the field
        const rawValue = field.value;
        // Extracted numbers from a raw value
        const numericValue = this.getNumber(rawValue);
        // Building a price string
        const formattedValue = this.formatPriceValue(numericValue);

        // Update calculations data
        this.calculationsData.payoff = numericValue;
        // Update field value with a price string
        field.value = formattedValue;

        // Validation: payoff field value cannot be greater than the trade in field value.
        // If so, update the trade in field value
        let tradeIn = this.calculationsData.tradeIn ?? 0;
        if (tradeIn < numericValue) {
            this.calculationsData.tradeIn = numericValue;
            this.tradeInField.value = formattedValue;
        }

        // Updating the down payment field
        const downPayment = this.getDownPaymentFromAdditionalFields();
        this.calculationsData.downPayment = downPayment;
        this.downPaymentField.value = this.formatPriceValue(downPayment);

        // Refresh the price
        this.refreshPrice();
    }

    /**
     * Fires when the additional down payment field is being changed
     * @param {HTMLElement} field
     */
    additionalDownPaymentChangeHandler(field) {
        // Raw value of the field
        const rawValue = field.value;
        // Extracted numbers from a raw value
        const numericValue = this.getNumber(rawValue);
        // Building a price string
        const formattedValue = this.formatPriceValue(rawValue);

        // Update calculations data
        this.calculationsData.additionalDownPayment = numericValue;
        // Update field value with a price string
        field.value = formattedValue;

        // Updating the down payment field
        const downPayment = this.getDownPaymentFromAdditionalFields();
        this.calculationsData.downPayment = downPayment;
        this.downPaymentField.value = this.formatPriceValue(downPayment);

        // Refresh the price
        this.refreshPrice();
    }

    /**
     * Set all the fields data from the calculationsData object
     */
    updateFieldsByCalculationsData() {
        // Price Field
        this.priceField.value =
            this.calculationsData.networkPrice !== null
                ? this.formatPriceValue(this.calculationsData.networkPrice)
                : '';
        // Down Payment Field
        this.downPaymentField.value =
            this.calculationsData.downPayment !== null
                ? this.formatPriceValue(this.calculationsData.calculationsData)
                : '';
        // Trade In Field
        this.tradeInField.value =
            this.calculationsData.tradeIn !== null
                ? this.formatPriceValue(this.calculationsData.tradeIn)
                : '';
        // Payoff Field
        this.payoffField.value =
            this.calculationsData.payoff !== null
                ? this.formatPriceValue(this.calculationsData.payoff)
                : '';
        // Additional Down Payment Field
        this.additionalDownPaymentField.value =
            this.calculationsData.additionalDownPayment !== null
                ? this.formatPriceValue(
                      this.calculationsData.additionalDownPayment,
                  )
                : '';
        // Term Field
        this.setSelectFieldValue(this.termField, this.calculationsData.term);
        // Finance Rate Field
        this.setInputRadioValue(
            this.financeRateFields,
            this.calculationsData.financeRate,
        );
        // Credit Score Rate Field
        this.setSelectFieldValue(
            this.creditScoreRateField,
            this.calculationsData.creditScoreRate,
        );
        // Custom Rate Field
        this.customRateField.value =
            this.calculationsData.customRate !== null
                ? this.formatPercentValue(this.calculationsData.customRate)
                : '';
    }

    /**
     * Fires when the additional down-payments-fields area button is clicked
     * @param event
     */
    downPaymentFieldsToggle(event) {
        event.preventDefault();

        this.downPaymentsFieldsOpened = !this.downPaymentsFieldsOpened;
        this.updateDownPaymentsArea();
    }

    /**
     * Show/Hide additional down-payments-fields area
     */
    updateDownPaymentsArea() {
        let iconPlus = this.downPaymentExpandButton.querySelector(
            `[data-role='arrow-down']`,
        );
        let iconMinus = this.downPaymentExpandButton.querySelector(
            `[data-role='arrow-up']`,
        );
        let wrapper = this.paymentWrapper;

        if (this.downPaymentsFieldsOpened) {
            jQuery(this.downPaymentsFields).slideDown('fast', () => {
                this.downPaymentField.disabled = true;
                this.tradeInField.disabled = false;
                this.payoffField.disabled = false;
                this.additionalDownPaymentField.disabled = false;
                iconPlus.classList.remove('flex');
                iconPlus.classList.add('hidden');
                iconMinus.classList.remove('hidden');
                iconMinus.classList.add('flex');
                wrapper.classList.remove('rounded-xl');
            });
        } else {
            jQuery(this.downPaymentsFields).slideUp('fast', () => {
                this.downPaymentField.disabled = false;
                this.tradeInField.disabled = true;
                this.payoffField.disabled = true;
                this.additionalDownPaymentField.disabled = true;
                iconMinus.classList.remove('flex');
                iconMinus.classList.add('hidden');
                iconPlus.classList.remove('hidden');
                iconPlus.classList.add('flex');
                wrapper.classList.add('rounded-xl');
            });
        }
    }

    /**
     * Set the passed value for the passed select field
     * @param {HTMLSelectElement} select
     * @param value
     */
    setSelectFieldValue(select, value) {
        const options = select.querySelectorAll('option');
        [...options].map((option) => {
            options.selected = option.value === value;
        });
    }

    /**
     * Finds an input element (among the passed) with the same value as the passed one and make it checked
     * @param {HTMLCollection} inputs
     * @param value
     */
    setInputRadioValue(inputs, value) {
        [...inputs].map((input) => {
            input.checked = input.value === value;
        });
    }

    /**
     * Return a price representation string for the passed value
     * @param rawValue
     * @return {string}
     */
    formatPriceValue(rawValue) {
        // Remove non-numeric characters
        let numericValue = this.getNumber(rawValue);
        numericValue = Math.max(0, numericValue);

        // Format the value using Intl.NumberFormat
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(numericValue);
    }

    /**
     * Returns a percent representation string for the passed value
     * @param rawValue
     * @return {string}
     */
    formatPercentValue(rawValue) {
        // Remove non-numeric characters
        let numericValue = this.getNumber(rawValue);
        numericValue = Math.min(100, Math.abs(this.getFloat(rawValue)));

        return new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(numericValue / 100);
    }

    /**
     * Returns a float number for the passed value
     * @param rawValue
     * @return {number}
     */
    getFloat(rawValue) {
        // Return 0 if it's a NaN and raw value if it's a valid number
        if (typeof rawValue === 'number') {
            return isNaN(rawValue) ? 0 : rawValue;
        }

        let parsedValue = parseFloat(rawValue);

        return isNaN(parsedValue) ? 0 : parsedValue;
    }

    /**
     * Returns a number for the passed value
     * @param rawValue
     * @return {number}
     */
    getNumber(rawValue) {
        // Check if rawValue is already a number
        if (typeof rawValue === 'number') {
            return isNaN(rawValue) ? 0 : rawValue;
        }

        // If rawValue is not a number, try converting it to a number
        let numericValue = Number.isFinite(rawValue)
            ? rawValue
            : rawValue.replace(/\D/g, '');

        // Use parseFloat to convert numericValue to a floating-point number
        let parsedValue = parseFloat(numericValue);

        // Check if parsedValue is a valid number
        return isNaN(parsedValue) ? 0 : parsedValue;
    }

    /**
     * Updates the price area
     */
    refreshPrice() {
        const updatedPrice = this.calculate();
        const updatedPriceFormatted = `<span class="text-[#103795] text-2xl">${this.formatPriceValue(
            updatedPrice,
        )}</span><span class="text-base">/mo</span>`;

        this.calculationsData = {
            ...this.calculationsData,
            estimatedMonthlyPayments: `${this.formatPriceValue(
                updatedPrice,
            )}/mo`,
        };
        this.calculationResultsElement.innerHTML = updatedPriceFormatted;

        this.updateHiddenInput();
    }

    /**
     * Finds particular hidden input within the person info form and updates its value with encoded calculator's data
     */
    updateHiddenInput() {
        const personalInfoForm =
            this.personalInfoFormContainer?.querySelector(`form[id^="gform_"]`);
        if (!personalInfoForm) {
            return;
        }

        const hiddenInput = personalInfoForm.querySelector(
            `input.gform_hidden[name=input_10]`,
        );
        if (hiddenInput) {
            hiddenInput.value = JSON.stringify(this.calculationsData);
        }
    }

    /**
     * Returns a calculated value based on the calculationsData object
     * @return {number}
     */
    calculate() {
        // P is the principal loan amount (the initial amount borrowed)
        const P = this.getPrincipalLoan();
        // r is the monthly interest rate
        const r = this.getMonthlyInterestRate();
        // n is the number of months (loan term)
        const n = this.getNumberOfMonths();

        // Result
        const result = (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);

        return Math.max(result, 0);
    }

    /**
     * Fires when the calculator's Submit button is clicked
     * @param {Event} event
     */
    calculatorSubmitButtonHandler(event) {
        event.preventDefault();

        const personalInfoForm =
            this.personalInfoFormContainer.querySelector(`form[id^="gform_"]`);
        if (!personalInfoForm) {
            return;
        }

        let isVisible = Boolean(
            this.personalInfoFormContainer.dataset?.visible === 'true',
        );
        if (isVisible) {
            personalInfoForm.submit();
        } else {
            jQuery(this.calculatorSubmitButton).fadeOut(400);
            jQuery(this.personalInfoFormContainer).slideDown(400, () => {
                this.personalInfoFormContainer.dataset.visible = 'true';
            });
        }
    }

    /**
     * Returns current principal load value
     * @return {number}
     */
    getPrincipalLoan() {
        return (
            (this.calculationsData.networkPrice ?? 0) - this.getDownPayment()
        );
    }

    /**
     * returns down payment value based on the calculationsData.tradeIn, calculationsData.payoff and calculationsData.additionalDownPayment values
     * @return {number}
     */
    getDownPaymentFromAdditionalFields() {
        return (
            (this.calculationsData.tradeIn ?? 0) -
            (this.calculationsData.payoff ?? 0) +
            (this.calculationsData.additionalDownPayment ?? 0)
        );
    }

    /**
     * Returns current down payment value
     * @return {number}
     */
    getDownPayment() {
        return this.calculationsData.downPayment ?? 0;
    }

    /**
     * Returns current monthly interest rate
     * @return {number}
     */
    getMonthlyInterestRate() {
        const rate =
            this.calculationsData.financeRate === 'credit-score-rate'
                ? this.calculationsData.creditScoreRate ?? 0
                : this.calculationsData.customRate ?? 0;
        return rate / 12 / 100;
    }

    /**
     * Returns term
     * @return {number}
     */
    getNumberOfMonths() {
        return this.calculationsData.term;
    }
}

/**
 * Initializes calculator for all the blocks on a current page
 */
function modalCalculatePayments() {
    const blocks = document.querySelectorAll(
        `[data-module='modalCalculatePayments']`,
    );
    blocks.forEach((block) => {
        new PaymentsCalculator(block);
    });
}

export default modalCalculatePayments;
