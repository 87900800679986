import MicroModal from 'micromodal';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function modalSliderA1() {
    const modalHook = '.js-slider-modal';

    let swiperInstance = null;

    MicroModal.init({
        onShow: (modal) => {
            // Get index of clicked slide and set it to modal initial state
            const parentSlider =
                document.querySelector(modalHook)?.dataset.parentSlider;

            const mainSlideActive = document.querySelector(
                `.${parentSlider} .swiper-slide-active`,
            )?.dataset.slideIndex;

            swiperInstance = new Swiper(`${modalHook} .swiper`, {
                speed: 400,
                grabCursor: true,
                slidesPerView: 1,
                initialSlide: +mainSlideActive ?? 0,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });

            swiperInstance.update();
        },
        onClose: () => {
            swiperInstance.destroy();
            document.body.classList.remove('l-body--modal-open');
        },
        disableScroll: true,
        awaitOpenAnimation: false,
        awaitCloseAnimation: true,
    });
}

export default modalSliderA1;
