/**
 * Each instance of this class have a number animations for the passed selector elements
 */

import Helpers from '/src/global/js/helpers/helpers';

class NumbersAnimation {
    /**
     * Main block container
     * @type {HTMLElement}
     */
    container;

    /**
     * Whether the block was already viewed or not
     * @type {Boolean}
     */
    isViewed = false;

    /**
     * HTMLCollection of number blocks
     * @type {HTMLCollection}
     */
    numberBlocks;

    /**
     * Selector for inner number blocks
     * @type {String}
     */
    numberItemSelector = '.js-animated-number';

    /**
     * Initialize a new instance of the class
     * @param {HTMLElement} container
     * @param {String} numberItemSelector
     */
    constructor(container, numberItemSelector = null) {
        this.container = container;
        if (numberItemSelector) {
            this.numberItemSelector = numberItemSelector;
        }
        this.numberBlocks = this.container.querySelectorAll(
            this.numberItemSelector,
        );

        // On page load
        this.init();
        // On scroll event
        window.addEventListener('scroll', this.init.bind(this));
    }

    /**
     * Core functionality
     */
    init() {
        if (
            // Check if the element is scrolled into view
            Helpers.isScrolledIntoView($(this.container)) &&
            !this.isViewed
        ) {
            // Set 'viewed_1' flag to true to indicate that the element has been viewed
            this.isViewed = true;

            // Animate all the numbers
            [...this.numberBlocks].map((numberBlock) => {
                if (numberBlock.innerText !== '') {
                    const finalNumber = parseInt(
                        numberBlock.innerText.replace('.', ''),
                        10,
                    );

                    // Set duration based on number value
                    const duration = finalNumber < 1000 ? 2000 : 3200;

                    $(numberBlock)
                        .prop('Counter', 0) // Set the initial value of 'Counter' property to 0
                        .animate(
                            {
                                Counter: finalNumber,
                            },
                            {
                                // Animation options
                                duration: duration,
                                easing: 'swing',
                                step: function (now) {
                                    let newValue = Math.ceil(now);
                                    //increase animation step
                                    if (finalNumber < 200) {
                                        newValue = Math.floor(newValue);
                                    } else {
                                        newValue =
                                            Math.floor(newValue / 10) * 10;
                                    }
                                    $(this).text(
                                        Math.ceil(newValue).toLocaleString(),
                                    );
                                },
                                complete: function (arg) {
                                    $(this).text(finalNumber.toLocaleString());
                                },
                            },
                        );
                }
            });
        }
    }
}

export default NumbersAnimation;
