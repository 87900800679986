import NumbersAnimation from '/src/global/js/animations/number-animation';

export default () => {
    // Retreive a list of numbers blocks
    const blocks = document.querySelectorAll('[data-module="statisticsA1"]');
    // Initialize a new NumbersAnimation item for each of them
    blocks.forEach((block) => {
        new NumbersAnimation(block, '.js-animated-number-a');
    });
};
