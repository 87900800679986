class FavoritesManager {
    /**
     * Under this key a list of favourites listings are stored in the localStorage
     * @type {string}
     */
    static STORAGE_KEY = 'cc_wp_favourites';

    constructor() {
        // this.favorites = JSON.parse(localStorage.getItem(FavoritesManager.STORAGE_KEY)) || {};
    }

    /**
     * Method to toggle favorite status of a product
     * @param {object} product
     */
    toggleFavorite(product) {
        const postId = parseInt(product.postId);
        const favourites = this.getFavourites();
        // Check if postId exists in favorites
        if (favourites[postId] !== undefined) {
            // If postId exists, remove it from favorites
            delete favourites[postId];
            // Dispatch custom event for favorite removal
            this._dispatchEvent('favoriteRemoved', product);
        } else {
            // If postId doesn't exist, add it to favorites
            favourites[postId] = product;
            // Dispatch custom event for favorite addition
            this._dispatchEvent('favoriteAdded', product);
        }
        let updatedValue = JSON.stringify(favourites);
        // Update favorites in localStorage
        localStorage.setItem(FavoritesManager.STORAGE_KEY, updatedValue);
        this._dispatchEvent('favoriteModified', updatedValue);
    }

    /**
     * Method to check if a product is in favorites
     * @param {number} productId
     * @return {boolean}
     */
    isInFavourites(productId) {
        return this.getFavourites()[productId] !== undefined;
    }

    /**
     * Returns a list of currently stored favourites listings
     * @return {*|{}}
     */
    getFavourites() {
        return (
            JSON.parse(localStorage.getItem(FavoritesManager.STORAGE_KEY)) || {}
        );
    }

    /**
     * Clear the favourites list
     */
    clearFavourites() {
        const newValue = JSON.stringify({});
        localStorage.setItem(FavoritesManager.STORAGE_KEY, newValue);
        this._dispatchEvent('favoriteModified', newValue);
    }

    /**
     * Remove listing from the favourites list by the passed Listing ID
     * @param postId
     */
    removeListingFromFavourites(postId) {
        const favourites = this.getFavourites();
        const listing = favourites[postId];
        if (listing === undefined) {
            return;
        }

        // If postId exists, remove it from favorites
        delete favourites[postId];
        let updatedValue = JSON.stringify(favourites);
        this._dispatchEvent('favoriteRemoved', listing);
        // Update favorites in localStorage
        localStorage.setItem(FavoritesManager.STORAGE_KEY, updatedValue);
        // Dispatch custom event for favorite removal
        this._dispatchEvent('favoriteModified', updatedValue);
    }

    /**
     * Method to dispatch custom event
     * @param {string} eventName
     * @param {object} product
     * @private
     */
    _dispatchEvent(eventName, product) {
        const event = new CustomEvent(eventName, { detail: { product } });
        window.dispatchEvent(event);
    }
}

export default FavoritesManager;
