import AOS from 'aos';
function showAccordion() {
    // CSS classes of Accordion items.
    const selector = {
        block: '.js-accordion',
        group: '.js-accordion_group',
        groupInner: '.js-accordion_group-inner',
        heading: '.js-accordion_heading',
        link: '.js-accordion_link',
        text: '.js-accordion_text',
        track: '.js-accordion_track',
    };

    // Active state classes.
    const state = {
        groupActive: 'is-active',
        bgActive: 'bg-grey-color-2',
        borderActive: 'border-grey-color-2',
        trackActive: 'rotate-180',
    };

    /**
     * Toggles the active class, rotates the control track, and slides the text element up or down.
     * @function
     * @param {Event} e - The click event.
     */
    const toggleAccordion = (e) => {
        e.preventDefault();

        const group = e.target.closest(selector.group);
        const groupInner = e.target.closest(selector.groupInner);
        const text = group.querySelector(selector.text);
        const track = group.querySelector(selector.track);

        group.classList.toggle(state.groupActive);
        track.classList.toggle(state.trackActive);
        groupInner.classList.toggle(state.bgActive);
        groupInner.classList.toggle(state.borderActive);
        $(text).slideToggle(300); // jQuery is used here, hence the eslint-disable-next-line comment.

        // refresh Animation on Scroll module Events
        setTimeout(function () {
            AOS.refresh();
        }, 300);
    };

    /**
     * Sets the initial state of the accordion
     * @function
     */
    const setAccordionInitialState = () => {
        // The accordion blocks.
        const items = document.querySelectorAll(selector.block);

        // Loop through each accordion block.
        items.forEach((item) => {
            // Open all accordion items by default
            const links = item.querySelectorAll(selector.link);
            links.forEach((link) => {
                setTimeout(() => {
                    link.click();
                }, 700);
            });
        });
    };

    /**
     * Adds the Accordion click function as a click event listener to each heading link element.
     */
    document.querySelectorAll(selector.link).forEach((link) => {
        link.addEventListener('click', toggleAccordion);
    });

    /**
     * Run setAccordionInitialState function when the DOM is ready
     */
    $(document).ready(() => {
        setAccordionInitialState();
    });
}

export default showAccordion;
