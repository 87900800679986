import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function galleryA() {
    const hooks = {
        main: '.js-swiper-container--main',
        column: '.js-swiper-container--column',
    };

    const main = document.querySelector(hooks.main);
    const column = document.querySelector(hooks.column);

    if (!main || !column) {
        return;
    }

    const mainSwiper = new Swiper(hooks.main, {
        speed: 400,
        slidesPerView: 1,
        spaceBetween: 30,
        autoplay: false,
        watchOverflow: true,
    });

    const columnSwiper = new Swiper(hooks.column, {
        speed: 400,
        slidesPerView: 2,
        spaceBetween: 16,
        direction: 'horizontal',
        longSwipes: true,
        watchOverflow: true,
        breakpoints: {
            640: {
                slidesPerView: 2,
                direction: 'vertical',
            },

            1024: {
                slidesPerView: 2,
                direction: 'vertical',
            },
        },
    });
    //

    // Fix bug with flex parent
    columnSwiper.update();
    mainSwiper.update();

    mainSwiper.on('slideChangeTransitionStart', function () {
        let index_currentSlide = mainSwiper.realIndex;
        let currentSlide = mainSwiper.slides[index_currentSlide];
        columnSwiper.slideTo(index_currentSlide, 1000, false);
    });

    columnSwiper.on('slideChangeTransitionStart', function () {
        let index_currentSlide = columnSwiper.realIndex;
        let currentSlide = columnSwiper.slides[index_currentSlide];
        mainSwiper.slideTo(index_currentSlide, 1000, false);
    });

    columnSwiper.on('click', function (pointerup) {
        mainSwiper.slideTo(pointerup.clickedIndex);
    });
}

export default galleryA;
