import getJsonFromAttr from './get-json-from-attr.js';
import toggleClass from './toggle-class.js';
import {
    addClassVanillaJs,
    removeClassVanillaJs,
} from './vanilla-js-classes.js';

/**
 * Class containing helper functions for manipulating the DOM
 * @class
 */
class Helpers {
    /**
     * Function to convert a JSON string to an object
     * @param {string} jsonData - JSON string to be converted
     * @returns {object} - converted JSON object
     */
    static getJsonFromAttr(jsonData) {
        return getJsonFromAttr(jsonData);
    }

    /**
     * Function to toggle a class on an element
     * @param {HTMLElement} element - element to toggle class on
     * @param {string} className - class to toggle
     */
    static toggleClass(element, className) {
        return toggleClass(element, className);
    }

    /**
     * Function to add a class to multiple elements
     * @param {HTMLElement[]} elements - elements to add class to
     * @param {string} className - class to add
     * @param {HTMLElement} [exclude=false] - An optional element to exclude from adding the class. In general used for `this` element in the loop.
     * @param {string[]} [events=[]] - events to attach to elements after class is added
     */
    static addClassVanillaJs(
        elements,
        className,
        exclude = false,
        events = [],
    ) {
        return addClassVanillaJs(elements, className, exclude, events);
    }

    /**
     * Function to remove a class from multiple elements
     * @param {HTMLElement[]} elements - elements to remove class from
     * @param {string} className - class to remove
     * @param {HTMLElement} [exclude=false] - An optional element to exclude from removing the class. In general used for `this` element in the loop.
     * @param {string[]} [events=[]] - events to attach to elements after class is removed
     */
    static removeClassVanillaJs(
        elements,
        className,
        exclude = false,
        events = [],
    ) {
        return removeClassVanillaJs(elements, className, exclude, events);
    }

    /**
     * Checks if a specified element is currently scrolled into view within the viewport
     * @param {JQuery<HTMLElement>} elem
     */
    static isScrolledIntoView(elem) {
        if (!elem.length) {
            // If the element is not found or does not exist, return false
            return false;
        }

        // Get the current position of the viewport's top edge
        let docViewTop = $(window).scrollTop();
        // Calculate the position of the viewport's bottom edge
        let docViewBottom = docViewTop + $(window).height();
        // Get the top position of the element
        let elemTop = elem.offset().top;
        // Calculate the position of the element's bottom edge
        let elemBottom = elemTop + elem.height();
        // Check if the element's bottom edge is above or at the same level as the viewport's bottom edge
        // AND if the element's top edge is below or at the same level as the viewport's top edge
        return elemBottom <= docViewBottom && elemTop >= docViewTop;
    }
}

export default Helpers;
