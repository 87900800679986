import Cookies from 'js-cookie';
import MicroModal from 'micromodal';

class UsersGateModal {
    /**
     * ID value for the modal window, containing the login form
     * @type {string}
     */
    static MODAL_ID = 'modal-users-gate';

    /**
     * ID value for the modal window, containing the login agreement
     * @type {string}
     */
    static AGREEMENT_MODAL_ID = 'modal-users-gate-agreement';

    /**
     * Interval in milliseconds, when the modal window appears
     * @type {number}
     */
    static INTERVAL = 500;

    /**
     * Cookie name, under which the authorised flag is stored
     * @type {string}
     */
    static COOKIE_NAME = 'ccUserAuthorised';

    /**
     * Cookie name, under which the agreement flag is stored
     * @type {string}
     */
    static COOKIE_AGREEMENT_NAME = 'ccUserAgreed';
    /**
     * The main HTML element, containing the form
     * @type {HTMLElement|null}
     */
    container = null;

    /**
     * Login form itself
     * @type {HTMLFormElement|null}
     */
    form = null;

    /**
     * Block, where error messages are being displayed
     * @type {HTMLElement|null}
     */
    errorMessagesContainer = null;

    /**
     * Whether the page is gateless.
     * @type {boolean}
     */
    isPageGateless = false;

    /**
     * Init a new class instance
     * @param {HTMLElement} container
     */
    constructor(container) {
        this.container = container;
        this.form = container.querySelector(`[data-role='users-gate-form']`);
        this.errorMessagesContainer = container.querySelector(
            `[data-role='error-messages']`,
        );
        this.isPageGateless = container.dataset.gateless === 'true';

        this.#init();
    }

    /**
     * Add event listeners
     */
    initListeners() {
        if (this.form) {
            this.form.addEventListener('submit', this.onFormSubmit.bind(this));
        }
    }

    /**
     * Start the class functionality
     * @private
     */
    #init() {
        if (this.isUserAuthorised()) {
            // Show agreement modal if user didn't agree earlier
            if (!this.didUserAgree()) {
                setTimeout(this.showAgreementModal, UsersGateModal.INTERVAL);
            }

            this.container.remove();
            return;
        }
        if (this.isPageGateless) {
            this.updateGatelessPageUsers();
            return;
        }

        this.initListeners();
        setTimeout(this.showModal, UsersGateModal.INTERVAL);
    }

    /**
     * Process users who land on the gateless pages.
     */
    updateGatelessPageUsers() {
        this.setUserCookie();
        this.container.remove();
    }

    /**
     * Fires when the form is being submitted
     * @param event
     */
    onFormSubmit(event) {
        event.preventDefault();

        //this.clearErrorMessages();
        const formData = this.formatFormData(new FormData(this.form));

        this.runAjaxCall(formData);
    }

    /**
     * Convert the passed FormData object into a plain object
     * @param {FormData} formData
     * @return {object}
     */
    formatFormData(formData) {
        // For dealing with multiselect values we need to filter it
        const data = {};
        formData.forEach(function (value, key) {
            const prev = data[key];
            if (prev) {
                data[key] = `${prev}, ${value}`;
            } else {
                data[key] = value;
            }
        });

        return data;
    }

    /**
     * Send AJAX request and process the response
     * @param {FormData} data
     */
    runAjaxCall(data) {
        const type = this.form.method;
        const action = this.form.getAttribute('action');

        this.showLoader();

        jQuery.ajax({
            type: type ?? 'POST',
            url: action ?? '/wp-admin/admin-ajax.php',
            data: data,
            beforeSend: () => {
                this.clearErrorMessages();
                this.setFormDisabledState();
            },
            success: (response) => {
                if (response.success) {
                    this.setUserCookie();
                    this.hideModal();
                    this.showAgreementModal();
                } else {
                    const errorMessage =
                        response.data?.error ?? 'Form contains errors';
                    this.showErrorMessages(errorMessage);
                }
            },
            complete: () => {
                this.hideLoader();
                this.setFormDisabledState(false);
            },
            error: (xhr) => {
                console.log('error:', xhr);
            },
        });
    }

    /**
     * Show the loader
     */
    showLoader() {
        // Find all elements with the data-id attribute set to "loader-login"
        const loaderElements = document.querySelectorAll(
            '[data-id="loader-login"]',
        );
        // Iterate over each loader element
        loaderElements.forEach((loader) => {
            // Set the display style to block to show the loader
            loader.style.display = 'block';
        });
    }

    /**
     * Hide the loader
     */
    hideLoader() {
        // Find all elements with the data-id attribute set to "loader-login"
        const loaderElements = document.querySelectorAll(
            '[data-id="loader-login"]',
        );
        // Iterate over each loader element
        loaderElements.forEach((loader) => {
            // Set the display style to none to hide the loader
            loader.style.display = 'none';
        });
    }

    /**
     * Set the passed disabled state for the form elements
     * @param {boolean} isDisabled
     */
    setFormDisabledState(isDisabled = true) {
        const elements = this.form.querySelectorAll('input, button');
        elements.forEach((element) => {
            element.disabled = isDisabled;
        });
    }

    /**
     * Hide the error messages block
     */
    clearErrorMessages() {
        if (!this.errorMessagesContainer) {
            return;
        }

        this.errorMessagesContainer.innerHTML = '';
        this.errorMessagesContainer.classList.add('hidden');
    }

    /**
     * Show the error messages block with the passed error string
     * @param {string} error
     */
    showErrorMessages(error) {
        if (!this.errorMessagesContainer) {
            return;
        }

        this.errorMessagesContainer.innerHTML = error;
        this.errorMessagesContainer.classList.remove('hidden');
    }

    /**
     * Returns true if the current user is already authorised
     * @return {boolean}
     */
    isUserAuthorised() {
        return parseInt(this.getUserCookie(), 10) > 0;
    }

    /**
     * Returns true if the current user already agreed
     * @return {boolean}
     */
    didUserAgree() {
        return parseInt(this.getUserAgreementCookie(), 10) > 0;
    }

    /**
     * Set specific cookie for the current user
     */
    setUserCookie() {
        Cookies.set(UsersGateModal.COOKIE_NAME, new Date().getTime());
    }

    /**
     * Reads specific cookie
     * @return {*}
     */
    getUserCookie() {
        return Cookies.get(UsersGateModal.COOKIE_NAME);
    }

    /**
     * Reads specific cookie
     * @return {*}
     */
    getUserAgreementCookie() {
        return Cookies.get(UsersGateModal.COOKIE_AGREEMENT_NAME);
    }

    /**
     * Show the modal window
     */
    showModal() {
        MicroModal.show(UsersGateModal.MODAL_ID, {
            openClass: 'is-open',
            closeTrigger: 'data-custom-close', // does not exist => cannot be closed
            disableScroll: true,
            debugMode: false,
        });
    }

    /**
     * Show the modal window that shows after the main one
     */
    showAgreementModal() {
        // Checks if modal exists
        if (!document.querySelector(`#${UsersGateModal.AGREEMENT_MODAL_ID}`)) {
            return;
        }

        MicroModal.show(UsersGateModal.AGREEMENT_MODAL_ID, {
            openClass: 'is-open',
            closeTrigger: 'data-custom-close', // does not exist => cannot be closed
            disableScroll: true,
            debugMode: false,
            onClose: () =>
                Cookies.set(
                    UsersGateModal.COOKIE_AGREEMENT_NAME,
                    new Date().getTime(),
                ),
        });
    }

    /**
     * Hides the modal window
     */
    hideModal() {
        MicroModal.close(UsersGateModal.MODAL_ID);
    }
}

function modalLogin() {
    const modalBlock = document.querySelector(`[data-module='modalLogin']`);
    if (modalBlock) {
        new UsersGateModal(modalBlock);
    }
}

export default modalLogin;
