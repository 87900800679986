function searchResultsA1() {
    // Forms of this block
    const forms = document.querySelectorAll('[data-role="typesense-search"]');
    [...forms].map((form) => {
        // State facets buttons
        const facetStateButtons = form.querySelectorAll(
            '[data-role="facet-state"]',
        );
        [...facetStateButtons].map((button) => {
            // Adding click event listener
            button.addEventListener('click', (event) => {
                event.preventDefault();

                // ID of the hidden input
                const hiddenInputID = 'temp-facet-input';

                // Name and value, will be used for query params
                const name = button.dataset?.name ?? '';
                const value = button.dataset?.value ?? '';
                if (!name || !value) {
                    return;
                }
                // Remove previously added input
                const prevInputs = form.querySelectorAll(`#${hiddenInputID}`);
                prevInputs.forEach((input) => {
                    input.remove();
                });

                // Creating a new custom input element and append it to the form HTML element
                const facetInput = document.createElement('input');
                facetInput.setAttribute('name', name);
                facetInput.setAttribute('value', value);
                facetInput.setAttribute('type', 'hidden');
                facetInput.setAttribute('id', hiddenInputID);
                form.appendChild(facetInput);

                // Triggering 'submit' event
                jQuery(form).submit();
            });
        });
    });
}

export default searchResultsA1;
