/**
 * Initializes a widget for translation dropdown functionality.
 * @module widgetTranslationDropdown
 */
const widgetTranslationDropdown = () => {
    /**
     * Toggle the visibility of options container and rotate arrow.
     *
     * @param {HTMLElement} selected - The selected element.
     */
    const toggleOptions = (selected) => {
        const optionsContainer = selected.nextElementSibling;
        const arrow = selected.parentNode.querySelector(
            '.m-widget-translation-dropdown__arrow',
        );

        if (optionsContainer.classList.contains('active')) {
            optionsContainer.classList.remove('active');
            arrow.classList.remove('rotate-180');
        } else {
            const currentActive = document.querySelector(
                '.m-widget-translation-dropdown__options-container.active',
            );

            if (currentActive) {
                currentActive.classList.remove('active');
                const anotherArrow = currentActive.parentNode.querySelector(
                    '.m-widget-translation-dropdown__arrow',
                );
                anotherArrow.classList.remove('rotate-180');
            }

            arrow.classList.add('rotate-180');
            optionsContainer.classList.add('active');
        }
    };

    /**
     * Close all dropdowns.
     */
    const closeDropdown = () => {
        const selectedAll = document.querySelectorAll(
            '.m-widget-translation-dropdown__selector-wrapper',
        );

        selectedAll.forEach((selected) => {
            const optionsContainer = selected.nextElementSibling;
            const arrow = selected.parentNode.querySelector(
                '.m-widget-translation-dropdown__arrow',
            );

            optionsContainer.classList.remove('active');
            arrow.classList.remove('rotate-180');
        });
    };

    const selectedAll = document.querySelectorAll(
        '.m-widget-translation-dropdown__selector-wrapper',
    );

    selectedAll.forEach((selected) => {
        const optionsContainer = selected.nextElementSibling;
        const optionsList = optionsContainer.querySelectorAll(
            '.m-widget-translation-dropdown__option',
        );

        selected.addEventListener('click', () => {
            toggleOptions(selected);
        });

        optionsList.forEach((o) => {
            o.addEventListener('click', () => {
                selected.querySelector(
                    '.m-widget-translation-dropdown__selected',
                ).innerHTML = o.querySelector('a').innerHTML;
                optionsContainer.classList.remove('active');

                const arrow = selected.parentNode.querySelector(
                    '.m-widget-translation-dropdown__arrow',
                );
                arrow.classList.remove('rotate-180');
            });
        });
    });

    /**
     * Close dropdown when clicking outside of it.
     *
     * @param {Event} e - The click event.
     */
    const handleOutclick = (e) => {
        if (!e.target.closest('.m-widget-translation-dropdown__select-box')) {
            closeDropdown();
        }
    };

    window.addEventListener('click', handleOutclick);

    const languageSwitchersBlocks = document.querySelectorAll(
        '.js-google-translate-switcher',
    );

    if (languageSwitchersBlocks.length) {
        [...languageSwitchersBlocks].forEach((languageSwitcherBlock) => {
            languageSwitcherBlock.addEventListener('click', (event) => {
                const target = event.target;
                const button = target.closest('a');
                if (!button) {
                    return;
                }

                event.preventDefault();

                const chosenLanguage = button.getAttribute('data-href');
                if (!chosenLanguage) {
                    return;
                }

                const googleTranslateSelect =
                    document.querySelector('.goog-te-combo');
                if (!googleTranslateSelect) {
                    return;
                }

                googleTranslateSelect.value = chosenLanguage;
                googleTranslateSelect.dispatchEvent(new Event('change'));
            });
        });
    }
};

export default widgetTranslationDropdown;
